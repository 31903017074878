import React, { useEffect } from "react";
import AppWidthWithContainer from "../../components/AppWidthContainer/AppWidthWithContainer";
import "./style.css";
import { HeaderCells } from "../../components/HeaderCells/HeaderCells";
import { TotalCount } from "../../components/TotalCount/TotalCount";
import { ShovelAnimation } from "../../components/ShovelAnimation/ShovelAnimation";
import shortedNumber from "helpers/shortedNumber";
import { useGlobalStore } from "stores/global";
import { LeagueLink } from "../../components/LeagueLink/LeagueLink";
import { Popup } from "components/Popup/Popup";
import { Gift } from "components/Gift/Gift";
import GiftButtonImage from "./assets/gift-button.png"
import BountyButtonImage from "./assets/bounty-button.png"



import { useNavigate } from "react-router-dom";


const Home = () => {

  const navigate = useNavigate();

  const tg_info = useGlobalStore(state => state.tg_info );
  const balance = useGlobalStore(state => state.user_info.balance );
  const rank = useGlobalStore(state => state.user_info.rank );
  const avatar = useGlobalStore(state => state.user_info.avatar );
  const status = useGlobalStore(state => state.farm.status );
  const gift = useGlobalStore(state => state.gift );

  const token_balance = useGlobalStore(state => state.user_info.token_balance );

  const google = useGlobalStore(state => state.google );

  const popup = useGlobalStore(state => state.popup );

  google.trackPageView('/', 'Home');




  useEffect(() => {
    const bottomNav = document.querySelector(".bottom-nav");
    bottomNav.classList.add("bottom-nav--dark");
    
    return () => {
        bottomNav.classList.remove("bottom-nav--dark");
    }
 },[])

  return (
    <AppWidthWithContainer className="home">
      <Popup isOpen = {popup.isOpen} onClose = {() => useGlobalStore.setState({popup: false})} img = {avatar} text = {tg_info.tg_name}/>
        <div className = "gift-button">
          <img src = {GiftButtonImage} alt = "" onClick = {() => navigate("/gift")}/>.
        </div>

        <div className = "bounty-button">
          <img src = {BountyButtonImage} alt = "" onClick = {() => navigate("/bounty")}/>
        </div>
      <div className="home__content" >
        <HeaderCells image = {avatar} text = {tg_info.tg_name} tokenBalance = {token_balance}/>
        <div className="home__total">
          <TotalCount count = {shortedNumber(balance)}/>
        </div>
        <div className="home__league-link">
          <LeagueLink balance = {balance} link={"/league-description"} />
        </div>
       
          <ShovelAnimation show = {status==1} link = {"/farm"}/>
          <Gift data = {gift} showOpenAnimation={false} showClaimPopup={true}/>
      </div>
    </AppWidthWithContainer>
  );
};

export default Home;

