export function unixToDateString(unixTimestamp, onlyDate = false) {
  const date = new Date(unixTimestamp * 1000);
  let day = date.getDate();
  let month = date.getMonth() + 1; // months are 0-based, so add 1
  const year = date.getFullYear().toString().substr(2); // get last two digits of year
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if(day < 10) {
    day = '0' + day;
  }

  if(month < 10) {
    month = '0' + month;
  }

  if(hours < 10) {
    hours = '0' + hours;
  }

  if(minutes < 10) {
    minutes = '0' + minutes;
  }

  if(onlyDate) {
    return `${day}/${month}/${year}`
  }

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}