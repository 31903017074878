import "./style.css";
import Telegram from "./assets/telegram.png"
import Tiktok from "./assets/tiktok.png"
import Twitter from "./assets/twitter.png"
import Youtube from "./assets/youtube.png"

export const SocialCard = ({type,text}) => {
    return <div className = "social-card">
        <img src = {getImgByType(type)}/>
        <span>{text}</span>
    </div>
}

const getImgByType = (type) => {

    const images = {
        "telegram": Telegram,
        "tiktok": Tiktok,
        "twitter": Twitter,
        "youtube": Youtube
    }

    return images[type]

} 