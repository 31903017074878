import "./style.css"
import KLD from "./assets/KLD.png"
import TON from "./assets/TON.png"
import Points from "./assets/Points.png"

export const BountyCard = ({type,text}) => {
    return <div className = "bounty-card">
        <img src = {getImgByType(type)} className = "bounty-card__img"/>
        <span>{text}</span>
    </div>
}

const getImgByType = (type) => {

    const images = {
        "KLD": KLD,
        "TON": TON,
        "points": Points
    }

    return images[type]

} 