import "./style.css"
import Loader from "components/icons/Loader"

export const KlondikeButton = ({style,img,text,clickHandler, loading = false}) => {

    if(loading) {
        return <div className = {`klondike-button ${style ? style: ""}`} onClick = {clickHandler}>
                <Loader fill="#582B02" width={24} height={24} />
        </div>
    }

    return <div className = {`klondike-button ${style ? style: ""}`} onClick = {clickHandler}>
            <span>{text}</span>
            {img && <img src = {img} alt = ""/>}
            
    </div>
}